<template>
    <router-link :to="`/units/track/${truck._id}`" class="tracking-item">
        <div class="name">{{ truck.name }}</div>
        <div>
            <div v-if="truck.driverDetails && truck.driverDetails[0]" class="driver">
                <span><i class="fa-regular fa-id-card"></i></span>
                <span>{{ truck.driverDetails[0].name }} {{ truck.driverDetails[0].lastname ? truck.driverDetails[0].lastname : '' }}</span>
            </div>
        </div>
        <div v-if="track" class="address">{{ track.gps.address }}</div>
    </router-link>
</template>

<script>
    export default {
        props: ['truck', 'tracks'],
        computed: {
            track() {
                if(!this.tracks) return null;
                if(!this.tracks.isTracked) return null;

                for(const vehicle of this.tracks.vehicles) {
                    if(vehicle.id === this.truck.vehicleId) return vehicle;
                }

                return null;
            },
        }
    }
</script>

<style lang="scss" scoped>
.tracking-item {
    color: $text;
    display: grid;
    row-gap: 5px;
    padding: 20px;
    .name {
        font-weight: 600;
    }
    .address {
        font-size: 12px;
        color: $buttonColor;
    }
    .driver {
        font-size: 13px;
        display: grid;
        column-gap: 10px;
        grid-template-columns: auto minmax(0, 1fr);
        align-items: center;
    }
}
</style>
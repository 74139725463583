<template>
    <div>
        <div class="tracking-wrapper">

            <div class="truckDetails">
                <div class="truck-driver-details">
                    <TruckTrackingItemVue class="item" v-for="truck of trucks" :key="truck._id" :truck="truck" :tracks="tracking" />
                </div>
            </div>
            
            <div class="map">
                <GoogleMaps  @loaded="mapLoaded" />
            </div>

        </div>

        <div style="display: none;">
            <VehicleMarker v-for="item of trucksWithLocations" :key="item.truck._id" :id="`truck-${item.truck._id}`" :truck="item.truck" :stats="item.stats"/>
        </div>
    </div>
</template>

<script>
import { toRaw } from '@vue/reactivity'
import GoogleMaps from '../components/GoogleMaps.vue'
import VehicleMarker from '../components/GoogleMaps/VehicleMarker.vue'
import moment from 'moment'
import TruckTrackingItemVue from '../components/Trucks/TruckTrackingItem.vue'


    export default {
        components: {
            GoogleMaps,
            VehicleMarker,
            TruckTrackingItemVue
        },
        data() {
            return {
                map: null,
                trucks: [],
                tracking: null,
                stats: {
                    gps: null
                },
                markers: {}
            }
        },
        computed: {
            locationDate() {
                if(this.stats && this.stats.gps) {
                    let date = moment(this.stats.gps.time);
                    return date.format('MMM D YYYY HH:mm');
                }
                return null;
            },
            trackingByVehicleIds() {
                let list = {};
                if(!this.tracking) return list;
                if(!this.tracking.vehicles) return list;
                for(const item of this.tracking.vehicles) {
                    list[item.id] = {
                        gps: item.gps
                    }
                }
                return list;
            },
            trucksWithLocations() {
                let list = [];
                for(const truck of this.trucks) {
                    if(truck.vehicleId && this.trackingByVehicleIds[truck.vehicleId]) {
                        list.push({
                            truck: truck,
                            stats: this.trackingByVehicleIds[truck.vehicleId]
                        });
                    }
                }
                return list;
            }
        },
        async mounted() {
            this.getTrucks();
            this.getTrackingDetails();
        },
        watch: {
            details: {
                deep: true,
                handler() {
                    
                }
            },
            trucksWithLocations() {
                this.addTruckLocations();
            }
        },
        methods: {
            getTrackingDetails() {
                this.ajax('GetTrackingDetails', {
                    url: `/myTracking`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.tracking = body;
                    }
                });
            },
            getTrucks() {
                this.ajax('getListOfTrucks', {
                    url: '/myTrucks',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.trucks = body;
                });
            },
            mapLoaded(map) {
                this.map = map;
            },
            async addTruckLocations() {

                await new Promise(resolve => { setTimeout(() => { resolve() }, 100) });
                for(const item of this.trucksWithLocations) {

                    let truckDOMid = 'truck-' + item.truck._id;
                    let el = document.getElementById(truckDOMid);
                    
                    let marker = new this.map.google.maps.marker.AdvancedMarkerView({
                        map: toRaw(this.map.map),
                        position: { lat: item.stats.gps.lat, lng: item.stats.gps.lng },
                        content: el,
                    });
                    
                    const element = marker.element;

                    ["focus", "pointerenter"].forEach((event) => {
                        element.addEventListener(event, () => {
                            marker.content.classList.add("shown");
                            element.style.zIndex = 1;
                        });
                    });

                    ["blur", "pointerleave"].forEach((event) => {
                        element.addEventListener(event, () => {
                            marker.content.classList.remove("shown");
                            element.style.zIndex = "";
                        });
                    });

                }
            },
            showCurrentLocation() {

                if(!this.stats.gps) return;
                if(!this.stats.gps.latitude) return;
                
                let marker = new this.map.google.maps.marker.AdvancedMarkerView({
                    map: toRaw(this.map.map),
                    position: { lat: this.stats.gps.latitude, lng: this.stats.gps.longitude },
                    content: this.$refs['Truck1'].$el,
                });
                
                const element = marker.element;

                ["focus", "pointerenter"].forEach((event) => {
                    element.addEventListener(event, () => {
                        marker.content.classList.add("shown");
                    });
                });

                ["blur", "pointerleave"].forEach((event) => {
                    element.addEventListener(event, () => {
                        marker.content.classList.remove("shown");
                    });
                });

                setTimeout(() => {
                    this.map.map.panTo(marker.position);
                    this.map.map.setZoom(20);
                }, 250);
                

            }
        }
    }
</script>


<style lang="scss" scoped>

.detailsItem {
    display: grid;
    column-gap: 20px;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    .address {
        font-size: 13px;
    }
    .time {
        font-size: 12px;
        opacity: 0.75;
    }
}


.tracking-wrapper {
    position: absolute;
    top: 100px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 400px minmax(300px, 1fr);
}

.truckDetails {
    border-right: 1px solid $borderColor;
    background: $bgSecondary;
    overflow-y: auto;
    height: 100%;
    .truck-driver-details {
        min-height: 100%;
        display: grid;
        align-content: start;
        align-items: start;
        .item {
            border-bottom: 1px solid $borderColor;
        }
        .details {
            display: grid;
            row-gap: 30px;
        }
    }
}

.map {
    background: #ccc;
    position: relative;
}


.fuelLevelGrid {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 10px;
    .percentage {
        font-size: 12px;
    }
}

.fuelLevelHolder {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $borderColor;
    .fuelPercent {
        height: 100%;
        background: $success;
        transition: ease 0.5s;
    }
}

.stats {
    display: grid;
    row-gap: 15px;
    .row {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        .name {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
        }
        .value {
            font-size: 12px;
        }
    }
}
</style>